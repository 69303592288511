import React from 'react';
import { Card, CardContent } from '@mui/material';
import {
  BooleanInput,
  DateInput,
  Edit,
  Loading,
  NumberInput,
  SaveButton,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useGetList,
} from 'react-admin';
import { useResourceAccess } from '../../hooks/useAccess';

const CustomToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const SubscriptionPlanMultiSelect = () => {
  const { data, isLoading, error } = useGetList('plans', {
    pagination: {
      perPage: 100,
      page: 0,
    },
    filter: {
      enabled: true,
    },
    meta: {
      selectAll: false,
    },
  });

  if (isLoading) {
    return <Loading></Loading>;
  }

  if (error) {
    return <div>Failed loading plans.</div>;
  }

  return (
    <SelectArrayInput
      source="allowAccessForSubscriptionPlans"
      choices={data?.map((d) => {
        return {
          id: d.id,
          name: d.name,
        };
      })}
    />
  );
};

const StoreConfig: React.FC = () => {
  const { canEditSettings } = useResourceAccess();
  const isRo = !canEditSettings();

  const transformValues = (values: any) => {
    return {
      ...values,
      orderLimitEmailRecipientList: values.orderLimitEmailRecipientList.filter((v: string) => !!v),
      userReachedCreditLimitEmailRecipientList: values.userReachedCreditLimitEmailRecipientList.filter(
        (v: string) => !!v
      ),
    };
  };

  return (
    <Card>
      <CardContent>
        <Edit
          resource="storesettings"
          id={'store'}
          actions={false}
          redirect={false}
          title=" (Store)"
          mutationMode={'pessimistic'}
          transform={transformValues}
        >
          <SimpleForm toolbar={!isRo ? <CustomToolbar /> : false}>
            <BooleanInput source="idRequired" />
            <TextInput source="orderPaymentSplitPercent" />
            <BooleanInput source="allowAccessForEveryone" />
            <BooleanInput source="allowAccessForStaff" />
            <DateInput source="allowAccessForSubscriptionCreatedBefore" />
            <SubscriptionPlanMultiSelect />
            <NumberInput
              source="orderLimitPerHour"
              min={1}
              defaultValue={10}
              parse={(value) => parseInt(value, 10) ?? 10}
            />
            <NumberInput
              source="orderLimitPerDay"
              min={1}
              defaultValue={10}
              parse={(value) => parseInt(value, 10) ?? 10}
            />
            <NumberInput
              source="orderLimitPerPeriod"
              min={1}
              defaultValue={10}
              parse={(value) => parseInt(value, 10) ?? 10}
            />
            <BooleanInput source="orderLimitEmailEnabled" />
            <TextInput
              source="orderLimitEmailRecipientList"
              format={(v) => (v ?? []).join(', ')}
              parse={(value: string) => value.split(',').map((v: string) => v.trim())}
            />
            <BooleanInput source="userReachedCreditLimitEmailEnabled" />
            <TextInput
              source="userReachedCreditLimitEmailRecipientList"
              format={(v) => (v ?? []).join(', ')}
              parse={(value: string) => value.split(',').map((v: string) => v.trim())}
            />
            <NumberInput
              source="directDebitCustomersPercentage"
              min={0}
              max={100}
              defaultValue={0}
              parse={(value) => parseInt(value, 10) ?? 0}
            />
            <BooleanInput source="authEnsureMFAEnabled" />
            <NumberInput source="authEnsureMFAForChangeSubscriptionEveryMinutes" min={0} />
            <NumberInput source="authEnsureMFAForChangePaymentDayEveryMinutes" min={0} />
          </SimpleForm>
        </Edit>
      </CardContent>
    </Card>
  );
};

export default StoreConfig;
