import React from 'react';
import { Card, CardContent } from '@mui/material';
import { BooleanInput, Edit, NumberInput, SaveButton, SimpleForm, Toolbar } from 'react-admin';
import { useResourceAccess } from '../../hooks/useAccess';

const CustomToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const UserApiConfig: React.FC = () => {
  const { canEditSettings } = useResourceAccess();
  const isRo = !canEditSettings();

  return (
    <Card>
      <CardContent>
        <Edit
          resource="settings"
          id={'api'}
          actions={false}
          redirect={false}
          title=" (User API)"
          mutationMode={'pessimistic'}
        >
          <SimpleForm toolbar={!isRo ? <CustomToolbar /> : false}>
            <BooleanInput source="authEnsureMFAEnabled" disabled={isRo}></BooleanInput>
            <NumberInput source="authEnsureMFAForChangePhoneEveryMinutes" disabled={isRo}></NumberInput>
            <NumberInput
              source="weeklyPlanCustomersPercentage"
              min={0}
              max={100}
              defaultValue={0}
              parse={(value) => parseInt(value, 10) ?? 0}
            />
          </SimpleForm>
        </Edit>
      </CardContent>
    </Card>
  );
};

export default UserApiConfig;
