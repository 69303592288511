import { GetListResult, GetOneParams, GetOneResult, RaRecord, UpdateParams, UpdateResult } from 'react-admin';
import { getJson, processApiError, putJson } from '../../../utils/api';
import IHandler from './IHandler';

export default class SettingHandler implements IHandler {
  static route = '/admin/settings';
  static resourceIdName = 'id';

  static async getListHandler<RecordType extends RaRecord = any>(): Promise<GetListResult<RecordType>> {
    return getJson(`${this.route}`, process.env.REACT_APP_API_BASE_URL).then(async (response) => {
      let { data: responseData } = await response.json();

      responseData = responseData.Items ?? responseData;
      responseData = responseData?.length > 0 ? responseData : [];

      responseData = responseData.map((data: any) => {
        const result = {
          ...data,
        };

        result.id = data[this.resourceIdName];
        delete result[this.resourceIdName];

        return {
          ...result,
        };
      });

      return {
        data: responseData,
        total: responseData.length,
      };
    });
  }

  static async getOneHandler<RecordType extends RaRecord = any>(
    resource: string,
    params?: GetOneParams<any>
  ): Promise<GetOneResult<RecordType>> {
    const id = params?.id;
    return getJson(`${this.route}/${id}`, process.env.REACT_APP_API_BASE_URL).then(async (response) => {
      const { data } = await response.json();

      data.id = id;
      return {
        data: {
          ...data,
        },
      };
    });
  }

  static async updateHandler<RecordType extends RaRecord = any>(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<RecordType>> {
    const { id } = params;
    params.id = id === 'undefined' ? '' : id;

    const payload = params.data;

    return putJson(`/${this.route}/${id}`, payload, process.env.REACT_APP_API_BASE_URL)
      .then(async (response) => {
        const { data } = await response.json();

        data.id = id;
        return {
          data: {
            ...data,
          },
        };
      })
      .catch(processApiError);
  }
}
