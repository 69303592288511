import React, { useCallback } from 'react';

import { Button, Menu as RaMenu, MenuItemLink, useLogout } from 'react-admin';
import UserIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import ScoreIcon from '@mui/icons-material/Score';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ChartIcon from '@mui/icons-material/PieChart';
import { useResourceAccess } from '../../hooks/useAccess';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  activeMenuItem: {
    backgroundColor: theme.palette.action.selected,
    fontWeight: 'bold',
  },
  menuItem: {},
}));

const CustomMenuItem = ({ to, exact, primaryText, leftIcon, onClick }: any) => {
  const classes = useStyles();
  const isXSmall = useMediaQuery('(max-width:600px)');
  const isActive = exact ? location.pathname === to.pathname : location.pathname.startsWith(to.pathname);
  return (
    <MenuItemLink
      className={isActive ? classes.activeMenuItem : classes.menuItem}
      to={to}
      primaryText={primaryText}
      leftIcon={leftIcon}
      onClick={onClick}
      sidebarIsOpen={isXSmall}
    />
  );
};

export const Menu: React.FC = () => {
  const { canViewHistory, canViewAnalytics } = useResourceAccess();
  const logout = useLogout();
  const handleLogout = useCallback(() => {
    logout();
  }, []);

  return (
    <RaMenu>
      {/*<DashboardMenuItem />*/}
      <CustomMenuItem to={{ pathname: '/users' }} primaryText="Users" leftIcon={<UserIcon />} />
      <CustomMenuItem
        to={{ pathname: '/subscriptions' }}
        primaryText="Subscriptions"
        leftIcon={<SubscriptionsIcon />}
      />
      <CustomMenuItem to={{ pathname: '/reminder' }} primaryText="SMS" leftIcon={<SubscriptionsIcon />} />
      <CustomMenuItem to={{ pathname: '/score' }} primaryText="Score" leftIcon={<ScoreIcon />} />
      <CustomMenuItem
        to={{ pathname: '/plans', search: `filter=${JSON.stringify({ enabled: 'true' })}` }}
        primaryText="Plans"
        leftIcon={<SettingsIcon />}
      />
      <CustomMenuItem to={{ pathname: '/cardverification' }} primaryText="CardVerification" leftIcon={<ScoreIcon />} />
      {canViewHistory() && (
        <CustomMenuItem to={{ pathname: '/history' }} primaryText="Activity" leftIcon={<SettingsIcon />} />
      )}
      {canViewAnalytics() && (
        <CustomMenuItem to={{ pathname: '/analytics' }} primaryText="Analytics" leftIcon={<ChartIcon />} />
      )}
      <CustomMenuItem to={{ pathname: '/kycmismatch' }} primaryText="KYC Mismatch" leftIcon={<SettingsIcon />} />
      <CustomMenuItem to={{ pathname: '/sanctions' }} primaryText="PEP/Sanctions" leftIcon={<SettingsIcon />} />
      <CustomMenuItem to={{ pathname: '/config' }} primaryText="Settings" leftIcon={<SettingsIcon />} />
      <Button onClick={handleLogout}>
        <>Logout</>
      </Button>
    </RaMenu>
  );
};
