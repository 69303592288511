import { Show, Tab, TabbedShowLayout } from 'react-admin';
import { useResourceAccess } from '../../hooks/useAccess';
import CrawlerConfig from '../CrawlerConfig';
import UserApiConfig from '../UserApiConfig';
import StoreConfig from '../StoreConfig';

export const SettingsEdit = () => {
  const { canEditSettings } = useResourceAccess();
  if (!canEditSettings()) {
    return null;
  }

  return (
    <Show resource="settings" id={''} actions={false} title={'Settings'}>
      <TabbedShowLayout>
        <Tab label="Crawler">
          <CrawlerConfig />
        </Tab>
        <Tab label="Payment">
          <StoreConfig />
        </Tab>
        <Tab label="User">
          <UserApiConfig />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default SettingsEdit;
