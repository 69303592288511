import React from 'react';
import { Card, CardContent } from '@mui/material';
import { Edit, NumberInput, SaveButton, SimpleForm, Toolbar } from 'react-admin';
import { useResourceAccess } from '../../hooks/useAccess';

const CustomToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const CrawlerConfig: React.FC = () => {
  const { canEditSettings } = useResourceAccess();
  const isRo = !canEditSettings();

  return (
    <Card>
      <CardContent>
        <Edit
          resource="settings"
          id={'crawler'}
          actions={false}
          redirect={false}
          title=" (Crawler)"
          mutationMode={'pessimistic'}
        >
          <SimpleForm toolbar={!isRo ? <CustomToolbar /> : false}>
            <NumberInput source="priceMarginPercent" disabled={isRo} />
            <NumberInput source="priceMarginAmount" disabled={isRo} />
            <NumberInput source="minPrice" disabled={isRo} />
            <NumberInput source="maxPrice" disabled={isRo} />
          </SimpleForm>
        </Edit>
      </CardContent>
    </Card>
  );
};

export default CrawlerConfig;
